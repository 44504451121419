function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace('./', '')] = r(item);
  });
  return images;
}

const images = importAll(
  require.context('../assets/app-icons', false, /\.(png|jpe?g|svg|webp)$/)
);

export const Assets_data = [
  {
    name: 'Nulls Brawl Plugin',
    rating: 4.9,
    size: '33mb',
    downloads: '72k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Nulls Brawl Private Server for iOS & Android',
    icon: images['oXEKnullsbrawl.png'],
    cover: images['oXEKnulls-brawl.webp'],
  },
  {
    name: 'Legend of Mushroom Plugin',
    rating: 5.0,
    size: '29mb',
    downloads: '71k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Gems iOS & Android',
    icon: images['wg9Wlegendofmushroom.webp'],
    cover: images['wg9Whq720.jpg'],
  },
  {
    name: 'CarX Drift Racing 2 Plugin',
    rating: 5.0,
    size: '29mb',
    downloads: '71k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources iOS & Android',
    icon: images['card.png'],
    cover: images['wg9Whq720.jpg'],
  },
  {
    name: 'Ultimate Draft Soccer Plugin',
    rating: 5.0,
    size: '29mb',
    downloads: '51k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Gems iOS & Android',
    icon: images['us.png'],
    cover: images['us.png'],
  },
    {
    name: 'Netflix Plugin',
    rating: 5.0,
    size: '29mb',
    downloads: '200k+',
    developer: 'AppsPro',
    category: 'Apps',
    description: 'Free Accounts',
    icon: images['nf.png'],
    cover: images['nf.png'],
  },
  {
    name: 'Car Parking Multiplayer 2 Plugin',
    rating: 5.0,
    size: '29mb',
    downloads: '200k+',
    developer: 'AppsPro',
    category: 'Apps',
    description: 'Unlimited Resources',
    icon: images['carp2.png'],
    cover: images['nf.png'],
  },
  {
    name: 'Max Plugin',
    rating: 5.0,
    size: '37mb',
    downloads: '232k+',
    developer: 'AppsPro',
    category: 'Apps',
    description: 'Free Accounts',
    icon: images['hbm.png'],
    cover: images['hbm.png'],
  },
  {
    name: 'Disney+ Plugin',
    rating: 5.0,
    size: '39mb',
    downloads: '236k+',
    developer: 'AppsPro',
    category: 'Apps',
    description: 'Free Accounts',
    icon: images['disp.png'],
    cover: images['disp.png'],
  },
  {
    name: 'Gta 5 Plugin',
    rating: 5.0,
    size: '57mb',
    downloads: '500k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Download',
    icon: images['gta.png'],
    cover: images['gta.png'],
  },
  {
    name: 'Tik Tok Followers Plugin',
    rating: 5.0,
    size: '90mb',
    downloads: '700k+',
    developer: 'AppsPro',
    category: 'Apps',
    description: 'Download',
    icon: images['tiki.png'],
    cover: images['tiki.png'],
  },
  {
    name: 'Instagram Followers Plugin',
    rating: 5.0,
    size: '67mb',
    downloads: '200k+',
    developer: 'AppsPro',
    category: 'Apps',
    description: 'Download',
    icon: images['is.png'],
    cover: images['is.png'],
  },
  {
    name: 'Gta 6 Plugin',
    rating: 5.0,
    size: '500mb',
    downloads: '1M+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Download',
    icon: images['gta6.png'],
    cover: images['gta6.png'],
  },
  {
    name: 'Telegram Premium Plugin',
    rating: 5.0,
    size: '650mb',
    downloads: '1M+',
    developer: 'AppsPro',
    category: 'Apps',
    description: 'Download',
    icon: images['tp.png'],
    cover: images['tp.png'],
  },
  {
    name: 'Paramount Plus Plugin',
    rating: 5.0,
    size: '452mb',
    downloads: '1.5M+',
    developer: 'AppsPro',
    category: 'Apps',
    description: 'Download',
    icon: images['pp.png'],
    cover: images['pp.png'],
  },
  {
    name: 'Crunchyroll Plugin',
    rating: 5.0,
    size: '69mb',
    downloads: '1.7M+',
    developer: 'AppsPro',
    category: 'Apps',
    description: 'Download',
    icon: images['cr.png'],
    cover: images['cr.png'],
  },
  {
    name: 'Discord Nitro Plugin',
    rating: 5.0,
    size: '69mb',
    downloads: '1.7M+',
    developer: 'AppsPro',
    category: 'Apps',
    description: 'Download',
    icon: images['disc.png'],
    cover: images['disc.png'],
  },
  {
    name: 'Twitch Bits Plugin',
    rating: 5.0,
    size: '69mb',
    downloads: '3.7M+',
    developer: 'AppsPro',
    category: 'Apps',
    description: 'Download',
    icon: images['tui.png'],
    cover: images['tui.png'],
  },
  {
    name: 'Vix Plugin',
    rating: 5.0,
    size: '69mb',
    downloads: '3.7M+',
    developer: 'AppsPro',
    category: 'Apps',
    description: 'Free Accounts',
    icon: images['vix.png'],
    cover: images['vix.png'],
  },
  {
    name: 'Hulu Plugin',
    rating: 5.0,
    size: '69mb',
    downloads: '1M+',
    developer: 'AppsPro',
    category: 'Apps',
    description: 'Free Accounts',
    icon: images['hulu.png'],
    cover: images['hulu.png'],
  },
  {
    name: 'Playstation Plus Plugin',
    rating: 5.0,
    size: '77mb',
    downloads: '299k+',
    developer: 'AppsPro',
    category: 'Apps',
    description: 'Free Accounts',
    icon: images['play.png'],
    cover: images['play.png'],
  },
  {
    name: 'Xbox Game Pass Plugin',
    rating: 5.0,
    size: '92mb',
    downloads: '549k+',
    developer: 'AppsPro',
    category: 'Apps',
    description: 'Free Accounts',
    icon: images['gamep.png'],
    cover: images['gamep.png'],
  },
  {
    name: 'Youtube Premium Plugin',
    rating: 5.0,
    size: '452mb',
    downloads: '1.5M+',
    developer: 'AppsPro',
    category: 'Apps',
    description: 'Download',
    icon: images['yt.png'],
    cover: images['yt.png'],
  },
  {
    name: 'Valorant Key Plugin',
    rating: 4.7,
    size: '452mb',
    downloads: '2.5M+',
    developer: 'AppsPro',
    category: 'Apps',
    description: 'Download',
    icon: images['vl.png'],
    cover: images['vl.png'],
  },
  {
    name: 'Amazon Prime Video Plugin',
    rating: 5.0,
    size: '650mb',
    downloads: '2M+',
    developer: 'AppsPro',
    category: 'Apps',
    description: 'Download',
    icon: images['apv.png'],
    cover: images['apv.png'],
  },
  {
    name: 'Tik Tok Coins Plugin',
    rating: 5.0,
    size: '18mb',
    downloads: '115k+',
    developer: 'AppsPro',
    category: 'Apps',
    description:
      'Unlimited 99,999 TikTok Coins added to your account. **CHECK YOUR ACCOUNT IS PUBLIC AND NOT PRIVATE**',
    icon: images['dsVWtiktokcoins1.webp'],
  },
  {
    name: 'Dream League Soccer Plugin',
    rating: 5.0,
    size: '25mb',
    downloads: '118k+',
    developer: 'AppsPro',
    category: 'Games',
    description:
      'Unlimited Coins',
    icon: images['dls.png'],
  },
  {
    name: '8 Ball Pool Plugin',
    rating: 5.0,
    size: '38mb',
    downloads: '270k+',
    developer: 'AppsPro',
    category: 'Games',
    description:
      'Max level, Unlimited coin, Unlimited cash, Hide name, All ballpot achievement, All League, 12 achievement (Win 1 match), Longline, Guidline in no guidline, No balls (semi autowin)',
    icon: images['pVB68ballpool.webp'],
    cover: images['pVB68bp_header.webp'],
  },
  {
    name: 'FC Mobile 25 Plugin',
    rating: 5.0,
    size: '32mb',
    downloads: '150k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited FC Points, Gems & Credits',
    icon: images['WIjCfcmobile2.png'],
  },
  {
    name: 'Spotify Premium Plugin',
    rating: 5.0,
    size: '38mb',
    downloads: '109k+',
    developer: 'AppsPro',
    category: 'Apps',
    description: 'Free accounts',
    icon: images['spoti.png'],
  },
  {
    name: 'COD Warzone Mobile',
    rating: 4.9,
    size: '35mb',
    downloads: '55k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Cod Points 999999, Aimbot, Walls, ESP, No Reload',
    icon: images['gC6Ycodwarzonemobile.webp'],
  },
  {
    name: 'ShortTV Bonus',
    rating: 5.0,
    size: '9mb',
    downloads: '45k+',
    developer: 'AppsPro',
    category: 'Apps',
    description:
      '100,000 Bonus Coins added to ShortTV Account, On iOS and Android Devices.',
    icon: images['wqdxshorttv.webp'],
  },
  {
    name: 'COD Mobile Plugin',
    rating: 4.9,
    size: '67mb',
    downloads: '49k+',
    developer: 'AppsPro',
    category: 'Games',
    description:
      'Wallhack, Aimbot, ESP, Unlimited COD Points, iOS & Android, No Reload',
    icon: images['OvETcodm.webp'],
  },
  {
    name: 'Microsoft Rewards 100k+',
    rating: 4.9,
    size: '27mb',
    downloads: '55k+',
    developer: 'AppsPro',
    category: 'Apps',
    description:
      '100,000 Added REAL Microsoft Rewards Plugin, Must be logged in on Microsoft Account.',
    icon: images['mYznmicrosoftrewards.webp'],
  },
  {
    name: 'Subway Surfers Plugin',
    rating: 4.3,
    size: '9mb',
    downloads: '350k+',
    developer: 'AppsPro',
    category: 'Games',
    description:
      'Unlimited Keys, Unlimited Coins, All Characters Unlocked, All Boosts Unlocked and Unlimited',
    icon: images['qhjnsubwaysurfers.webp'],
  },
  {
    name: 'Pokemon Go Spoofer',
    rating: 4.7,
    size: '27mb',
    downloads: '111k+',
    developer: 'AppsPro',
    category: 'Games',
    description:
      'iPogo is a simple, powerful and intuitive app that allows you to teleport to raids, skip animations, auto catch and much more!',
    icon: images['qRjCpokemongo3.webp'],
  },
  {
    name: 'Sims FreePlay Plugin',
    rating: 4.5,
    size: '22mb',
    downloads: '44k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Money, Vip Unlocked',
    icon: images['0RVLsimsfreeplay.webp'],
  },
  {
    name: 'Predictor Aviator',
    rating: 4.5,
    size: '42mb',
    downloads: '44k+',
    developer: 'AppsPro',
    category: 'Apps',
    description: 'Full Download for Free on iOS & Android',
    icon: images['vTLZpredaviator.png'],
  },
  {
    name: 'eFootball Plugin',
    rating: 5.0,
    size: '27mb',
    downloads: '102k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Money',
    icon: images['ef.jpeg'],
  },
    {
    name: 'Roblox Plugin',
    rating: 5.0,
    size: '21mb',
    downloads: '100k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Robux',
    icon: images['rb.png'],
  },
  {
    name: 'Dragon Ball : Legends Plugin',
    rating: 5.0,
    size: '29mb',
    downloads: '150k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Money',
    icon: images['dbz.png'],
  },
  {
    name: 'Monopoly Go Plugin',
    rating: 5.0,
    size: '22mb',
    downloads: '140k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['mn.png'],
  },
  {
    name: 'Royale Match Plugin',
    rating: 5.0,
    size: '24mb',
    downloads: '140k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['ry.png'],
  },
   {
    name: 'Traffic Rider Plugin',
    rating: 5.0,
    size: '29.2mb',
    downloads: '121k+',
    developer: 'AppsPro',
    category: 'AGames',
    description: 'Unlimited Resources',
    icon: images['tf.png'],
  },
   {
    name: 'Soccer Super Star Plugin',
    rating: 5.0,
    size: '30mb',
    downloads: '175k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['st.png'],
  },
   {
    name: 'Pubg Mobile Plugin',
    rating: 5.0,
    size: '31mb',
    downloads: '500k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['pg.png'],
  },
   {
    name: 'Coin Master Plugin',
    rating: 5.0,
    size: '35mb',
    downloads: '500k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['cm.png'],
  },
   {
    name: 'Free Fire Plugin',
    rating: 5.0,
    size: '36mb',
    downloads: '1M+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['ff.png'],
  },
   {
    name: 'Top Eleven Plugin',
    rating: 5.0,
    size: '32mb',
    downloads: '300k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['te.png'],
  },
   {
    name: 'TownShip Plugin',
    rating: 5.0,
    size: '32mb',
    downloads: '142k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['ts.png'],
  },
  {
    name: 'Candy Crush Soda Plugin',
    rating: 5.0,
    size: '33mb',
    downloads: '2M+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['cs.png'],
  },
  {
    name: 'Candy Crush Saga Plugin',
    rating: 5.0,
    size: '32mb',
    downloads: '3M+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['candysaga.png'],
  },
   {
    name: 'Futbol Strike Plugin',
    rating: 5.0,
    size: '21mb',
    downloads: '190k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['fs.png'],
  },
   {
    name: 'BloodStrike Plugin',
    rating: 5.0,
    size: '23mb',
    downloads: '100k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['bs.png'],
  },
  {
    name: 'Stumble Guys Plugin',
    rating: 5.0,
    size: '23mb',
    downloads: '3M+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['sg.png'],
  },
    {
    name: 'Clash Of Clans Plugin',
    rating: 5.0,
    size: '20mb',
    downloads: '10M+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['coc.png'],
  },
  {
    name: 'Football League Plugin',
    rating: 5.0,
    size: '27mb',
    downloads: '5M+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['fl.png'],
  },
   {
    name: 'Pokemon Go Plugin',
    rating: 5.0,
    size: '27mb',
    downloads: '15M+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['pgo.png'],
  },
   {
    name: 'Car Parking Multiplayer Plugin',
    rating: 5.0,
    size: '22mb',
    downloads: '17M+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['cparking.png'],
  },
   {
    name: 'Asphalt Unite Plugin',
    rating: 5.0,
    size: '23mb',
    downloads: '1M+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['unite.png'],
  },
    {
    name: 'One Piece Bounty Rush Plugin',
    rating: 5.0,
    size: '29mb',
    downloads: '2.2M+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['opbr.png'],
  },
   {
    name: 'Basketball Stars Plugin',
    rating: 5.0,
    size: '37mb',
    downloads: '500k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['bstars.png'],
  },
   {
    name: 'GTA San Andreas Plugin',
    rating: 5.0,
    size: '300mb',
    downloads: '500k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['sa.png'],
  },
   {
    name: 'Nba Infinite Plugin',
    rating: 5.0,
    size: '17mb',
    downloads: '500k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['nba.png'],
  },
   {
    name: 'Parchis Star Plugin',
    rating: 5.0,
    size: '19mb',
    downloads: '10M+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['ps.png'],
  },
  {
    name: 'Dragon City Mobile Plugin',
    rating: 5.0,
    size: '29mb',
    downloads: '10M+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['dc.png'],
  },
  {
    name: 'Nba Live Plugin',
    rating: 5.0,
    size: '29mb',
    downloads: '15M+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['nbalive.png'],
  },
  {
    name: 'Shadow Fight 2 Plugin',
    rating: 5.0,
    size: '32mb',
    downloads: '15M+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['sf.png'],
  },
  {
    name: 'Angry Birds 2 Plugin',
    rating: 5.0,
    size: '39mb',
    downloads: '30M+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['angry.png'],
  },
   {
    name: 'Farlight 84 Plugin',
    rating: 5.0,
    size: '39mb',
    downloads: '10k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['far.png'],
  },
   {
    name: 'Soccer Hero Plugin',
    rating: 5.0,
    size: '44mb',
    downloads: '16k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['sh.png'],
  },
  {
    name: 'Head Soccer Plugin',
    rating: 5.0,
    size: '19mb',
    downloads: '122k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['headsoccer.png'],
  },
  {
    name: 'Score Hero Plugin',
    rating: 5.0,
    size: '19mb',
    downloads: '112k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['scorehero.png'],
  },
  {
    name: 'Mortal Kombat Plugin',
    rating: 5.0,
    size: '21mb',
    downloads: '162k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['kombat.png'],
  },
  {
    name: 'NSF Sin Limites Plugin',
    rating: 5.0,
    size: '20mb',
    downloads: '162k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['nsf.png'],
  },
   {
    name: 'Pokemon Unite Plugin',
    rating: 5.0,
    size: '51mb',
    downloads: '192k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['pokemon.png'],
  },
   {
    name: 'CSR 2 Plugin',
    rating: 5.0,
    size: '36mb',
    downloads: '12k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['csr2.png'],
  },
   {
    name: 'NBA 2K 24 Plugin',
    rating: 5.0,
    size: '33mb',
    downloads: '17k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['nba2k.png'],
  },
  {
    name: 'MLB Perfect Inning 24 Plugin',
    rating: 5.0,
    size: '28mb',
    downloads: '102k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['mlb.png'],
  },
   {
    name: 'Golf Battle Plugin',
    rating: 5.0,
    size: '20mb',
    downloads: '152k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['golf.png'],
  },
  {
    name: 'Mario Kart Tour Plugin',
    rating: 5.0,
    size: '28mb',
    downloads: '11k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['mariokart.png'],
  },
   {
    name: 'F1 Clash Plugin',
    rating: 5.0,
    size: '90mb',
    downloads: '111k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['f1.png'],
  },
  {
    name: 'Yu Gi Oh! Master Duel Plugin',
    rating: 5.0,
    size: '99mb',
    downloads: '200k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['yugi.png'],
  },
  {
    name: 'UNO Plugin',
    rating: 5.0,
    size: '102mb',
    downloads: '1M+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['1.png'],
  },
   {
    name: 'OSM 24 Football Manager Game Plugin',
    rating: 5.0,
    size: '105mb',
    downloads: '1M+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['osm24.png'],
  },
   {
    name: 'SimCity Buildlt Plugin',
    rating: 5.0,
    size: '21mb',
    downloads: '1M+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['simcity.png'],
  },
   {
    name: 'Call Of Dragons Plugin',
    rating: 5.0,
    size: '25mb',
    downloads: '500k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['callof.png'],
  },
  {
    name: 'Baseball 9 Plugin',
    rating: 5.0,
    size: '28mb',
    downloads: '400k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['baseball9.png'],
  },
  {
    name: 'Dice Dreams Plugin',
    rating: 5.0,
    size: '20mb',
    downloads: '100k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['dice.png'],
  },
  {
    name: 'Monster Legends Plugin',
    rating: 5.0,
    size: '26mb',
    downloads: '250k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['monster.png'],
  },
   {
    name: 'Lord Mobile Godzilla Kong War Plugin',
    rating: 5.0,
    size: '27mb',
    downloads: '250k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['lordm.png'],
  },
   {
    name: 'Rise Of KingDoms Lost Crusade Plugin',
    rating: 5.0,
    size: '17mb',
    downloads: '350k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['rise.png'],
  },
   {
    name: 'Without Survival Plugin',
    rating: 5.0,
    size: '25mb',
    downloads: '450k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['survival.png'],
  },
   {
    name: 'Top Troops Plugin',
    rating: 5.0,
    size: '22mb',
    downloads: '150k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['top.png'],
  },
  {
    name: 'CarX Street Plugin',
    rating: 5.0,
    size: '29mb',
    downloads: '250k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['carx.png'],
  },
  {
    name: 'Drive Zone Online Plugin',
    rating: 5.0,
    size: '28mb',
    downloads: '150k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['drive.png'],
  },
    {
    name: 'Real Racing 3 Plugin',
    rating: 5.0,
    size: '37mb',
    downloads: '650k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['real.png'],
  },
  {
    name: 'Hill Climb Racing 2 Plugin',
    rating: 5.0,
    size: '12mb',
    downloads: '850k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['hill.png'],
  },
    {
    name: 'Hill Climb Racing Plugin',
    rating: 5.0,
    size: '11mb',
    downloads: '150k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['hillone.png'],
  },
    {
    name: 'Hungry Shark Evolution Plugin',
    rating: 5.0,
    size: '12mb',
    downloads: '131k+',
    developer: 'AppsPro',
    category: 'Games',
    description: 'Unlimited Resources',
    icon: images['shark.png'],
  },
];
